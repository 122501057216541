@import "../../../styles/build/scss/variables";

.stackContainer {
	flex: 1;
	padding: $logan-spacing-16;
	gap: $logan-spacing-24;
	border-radius: $logan-border-radius-16;

	// TODO aqib missing token
	border: $logan-border-width-1 solid #bee6cc;
	box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);

	.topContainer {
		.tabTitle {
			// TODO aqib missing token
			color: #2c2b2b;
		}

		.topBtnContainer {
			flex-wrap: wrap;
			gap: $logan-spacing-32;

			.extendLockIcon {
				font-size: $logan-font-size-24;
			}

			.primaryBtnIcon {
				color: $logan-background-white;
			}
		}
	}

	.bottomContainer {
		// margin-left: $logan-spacing-16;
		column-gap: $logan-spacing-64;
		flex-wrap: wrap;
	}
}
