@import "../../../../styles/build/scss/variables";

.cardContainer {
	flex: 1;
	flex-wrap: wrap;
	padding: $logan-spacing-12 $logan-spacing-16;
	margin-bottom: $logan-spacing-16;
	border-radius: $logan-spacing-8;
	border: $logan-border-width-1 solid $logan-outline-gray;
	background: $logan-background-white;
	gap: $logan-spacing-24 $logan-spacing-0;

	.dividerContainer {
		padding: $logan-spacing-4 $logan-spacing-0;

		.divider {
			height: 100%;
			border-right: $logan-border-width-1 solid $logan-outline-gray;
			background-color: $logan-outline-gray;
			margin: $logan-spacing-0 $logan-spacing-16;
		}
	}
}
