@import "../../styles/build/scss/variables";

.requiredErrorContainer {
	background: $logan-destructive-enabled;
	border-radius: $logan-border-radius-4;
	padding: $logan-spacing-6 $logan-spacing-16;
	margin-bottom: $logan-spacing-24;

	.errorIconContainer {
		padding: $logan-spacing-8 $logan-spacing-12 $logan-spacing-8
			$logan-spacing-0;

		.errorIcon {
			// TODO aqib missing token
			font-size: 22px;
			color: $logan-text-white;
		}
	}

	.textContainer {
		gap: $logan-spacing-4;
		padding: $logan-spacing-8 $logan-spacing-0;

		.headerText {
			color: $logan-text-white;
		}

		.bodyText {
			color: $logan-text-white;
		}
	}
}
