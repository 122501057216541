@import "../../../styles/build/scss/variables";

.stackContainer {
	flex: 1;
	padding: $logan-spacing-16;
	gap: $logan-spacing-24;
	border-radius: $logan-border-radius-16;

	// TODO aqib missing token
	border: $logan-border-width-1 solid #bee6cc;
	box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);

	.topContainer {
		.tabTitle {
			// TODO aqib missing token
			color: #2c2b2b;
			min-width: 240px;
		}

		.topBtnContainer {
			flex-wrap: wrap;
			gap: $logan-spacing-32;

			.downloadIcon {
				font-size: $logan-font-size-24;
			}

			.primaryBtnIcon {
				color: $logan-background-white;
			}
		}
	}

	.categoryContainer {
		gap: $logan-spacing-8;

		.categoryHeader {
			color: $logan-text-gray;
		}

		.selectContainer {
			width: 320px;
		}
	}

	.bottomContainer {
		// margin-left: $logan-spacing-16;
		flex: 1;
		row-gap: $logan-spacing-48;

		.conditionTableStack {
			// TODO aqib missing token
			flex: 1;
			gap: 20px;
			overflow: hidden;

			.approvalHeading {
				color: #7a66f9;
			}

			.approvalTableContainer {
				flex: 1;
				border-left: 6px solid #7a66f9;
			}

			.docsHeading {
				color: #ac3e55;
			}

			.docsTableContainer {
				flex: 1;
				border-left: 6px solid #ac3e55;
			}

			.fundingHeading {
				color: #959668;
			}

			.fundingTableContainer {
				flex: 1;
				border-left: 6px solid #959668;
			}

			.closingHeading {
				color: #ea3989;
			}

			.closingTableContainer {
				flex: 1;
				border-left: 6px solid #ea3989;
			}

			.purchaseHeading {
				color: #897fc3;
			}

			.purchaseTableContainer {
				flex: 1;
				border-left: 6px solid #897fc3;
			}
		}
	}
}
