@import "../../../styles/build/scss/variables";

.modalBodyContainer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	min-width: 540px;

	border-radius: $logan-border-radius-16;
	// TODO aqib missing token
	border: $logan-border-width-4 solid #bee6cc;
	background: $logan-background-white;

	padding: $logan-spacing-32;
	// TODO aqib missing token
	// gap: 40px;

	.animationContainer div {
		width: 220px;
	}

	.successText {
		color: #26aa5e;
	}
}
