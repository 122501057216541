@import "../../../styles/build/scss/variables";

p.heading3 {
	font-family: $logan-font-families-sofia-sans;
	font-weight: $logan-font-weights-regular;
	font-size: $logan-font-size-24;
	line-height: $logan-line-heights-32;
	letter-spacing: $logan-spacing-0;
	color: $logan-text-dark-green;
}

p.semibold {
	font-weight: $logan-font-weights-semibold;
}

p.error {
	// TODO aqib missing token
	color: #ec5656;
}
