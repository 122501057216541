@import "../../../styles/build/scss/variables";

.cardContainer {
	border-radius: $logan-border-radius-16;
	// TODO aqib missing token
	border: $logan-border-width-1 solid #eee9e9;
	background: $logan-background-white;
	// TODO aqib missing token
	box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
	// TODO aqib missing token
	padding: 28px $logan-spacing-32 $logan-spacing-32 $logan-spacing-32;
	gap: $logan-spacing-16;

	.topContainer {
		gap: $logan-spacing-4;

		.addressText {
			// TODO aqib missing token
			color: #2c2b2b;
		}

		.completeLoanContainer {
			gap: $logan-spacing-6;
			padding: $logan-spacing-8 $logan-spacing-0;
			align-items: center;

			.completeLoanLink {
				text-decoration: underline;
				color: $logan-text-gray;
			}

			.completeLoanIcon {
				font-size: $logan-font-size-16;
				color: $logan-text-gray;
			}
		}
	}

	.bottomContainer {
		flex-wrap: wrap;
		row-gap: $logan-spacing-24;

		.detailContainer {
			.detailDiv {
				gap: $logan-spacing-4;

				.detailsValue {
					color: $logan-text-black;
				}
			}
		}

		.dividerContainer {
			padding: $logan-spacing-8 $logan-spacing-0;

			.dividerLine {
				border-right: $logan-border-width-1 solid #c1dcc6;
				height: 100%;
				margin: $logan-spacing-0 $logan-spacing-24;
			}
		}
	}
}
