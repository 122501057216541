@import "../../../styles/build/scss/variables";

.MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-filledDefault.MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium {
	border-radius: $logan-border-radius-4;
	background: $logan-outline-green;

	// text
	font-family: $logan-font-families-sofia-sans;
	font-weight: $logan-font-weights-regular;
	font-size: $logan-font-size-14;
	line-height: $logan-line-heights-16;
	letter-spacing: $logan-spacing-0;
	color: $logan-text-white;

	.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiChip-deleteIcon.MuiChip-deleteIconMedium.MuiChip-deleteIconColorDefault.MuiChip-deleteIconFilledColorDefault {
		-webkit-tap-highlight-color: transparent;
		color: rgba(255, 255, 255, 0.7);
		font-size: 22px;
		cursor: pointer;
	}
}

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
	&.MuiAutocomplete-popupIndicator,
	&.MuiAutocomplete-clearIndicator {
		color: $logan-outline-green;
	}
}
