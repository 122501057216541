@import "../../../../styles/build/scss/variables";

.stackContainer {
	flex: 1;
	padding: $logan-spacing-24 $logan-spacing-32;
	gap: $logan-spacing-24;
	overflow: auto;

	.drawerHeaderContainer {
		.headerText {
			color: #2c2b2b;
		}
	}

	.docsContainer {
		padding: $logan-spacing-24 $logan-spacing-32;
		gap: $logan-spacing-8;

		background: $logan-background-white;
		border-radius: $logan-border-radius-16;
		// TODO aqib missing token
		border: $logan-border-width-1 solid #bee6cc;
		box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
	}

	.conditionsContainer {
		padding: $logan-spacing-24 $logan-spacing-32;
		gap: $logan-spacing-24;

		background: $logan-background-white;
		border-radius: $logan-border-radius-16;
		// TODO aqib missing token
		border: $logan-border-width-1 solid #bee6cc;
		box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
	}

	.labelText {
		color: $logan-text-black;
	}

	.uploadedFileContainer {
		gap: $logan-spacing-16;

		.fileListContainer {
			align-items: center;
			width: 100%;
			padding-left: $logan-spacing-16;
			gap: $logan-spacing-16;

			.fileIconContainer {
				padding: $logan-spacing-8;

				.fileIcon {
					font-size: $logan-font-size-24;
					color: $logan-outline-green;
				}
			}

			.fileNameContainer {
				gap: $logan-spacing-4;
			}

			.deleteIconContainer {
				padding: $logan-spacing-8;

				.deleteIcon {
					font-size: $logan-font-size-24;
					color: $logan-destructive-enabled;
				}
			}
		}
	}

	.drawerFooterContainer {
		gap: $logan-spacing-24;

		.cancelBtn {
			border: 2px solid $logan-button-secondary-enabled;

			.cancelText {
				color: $logan-button-secondary-enabled;
			}
		}
	}
}
