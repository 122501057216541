@import "../.././styles/build/scss/variables";

.rootContainer {
	display: flex;
	min-height: 100vh;
}

.drawerContainer {
	> div {
		// Your styles for the MuiDrawer paper element here
		background-color: $logan-side-menu-background;
		border-right: $logan-border-width-2 solid $logan-table-border-green;
	}
}

.drawerMobileContainer {
	> div:nth-child(3) {
		// Your styles for the mobile MuiDrawer paper element here
		background-color: $logan-side-menu-background;
		border-right: $logan-border-width-2 solid $logan-table-border-green;
	}
}

hr.dividerLine {
	border-right: $logan-border-width-1 solid $logan-table-border-green;
	background-color: $logan-side-menu-seperator;

	// TODO aqib missing token
	margin: $logan-spacing-16 20px;
}

header.appBar {
	background-color: $logan-background-white;

	> div {
		padding: $logan-spacing-16 $logan-spacing-24;
	}

	button.menuIcon {
		color: $logan-side-menu-outline;
	}

	.toolbarStack {
		flex: 1;
	}
}

.toolbarDiv {
	margin-top: $logan-spacing-16;
}

.logoContainer {
	padding: $logan-spacing-16 $logan-spacing-10;
}

ul.drawerList {
	margin-top: $logan-spacing-8;

	li {
		margin-bottom: $logan-spacing-8;
	}

	.drawerSelectedItem {
		background-color: $logan-side-menu-selected-background;
		border-left: $logan-border-width-4 solid $logan-side-menu-selected-border;
		padding: $logan-spacing-0;

		svg {
			fill: $logan-side-menu-selected-text;
			width: $logan-font-size-24;
			height: $logan-font-size-24;
		}

		.drawerSelectedText {
			color: $logan-side-menu-selected-text;
		}

		> div {
			padding: $logan-spacing-12 $logan-spacing-16;
			gap: $logan-spacing-12;

			> div {
				min-width: $logan-spacing-0;
			}
		}
	}

	.drawerItem {
		background-color: $logan-side-menu-unselected-background;
		padding: $logan-spacing-0;

		border-left: $logan-border-width-4 solid
			$logan-side-menu-unselected-background;

		svg {
			fill: $logan-side-menu-unselected-text;
			width: $logan-font-size-24;
			height: $logan-font-size-24;
		}

		> div {
			padding: $logan-spacing-12 $logan-spacing-16;
			gap: $logan-spacing-12;

			> div {
				min-width: $logan-spacing-0;
			}
		}

		.drawerText {
			color: $logan-side-menu-unselected-text;
		}
	}
}

.mainContainer {
	display: flex;
	flex-direction: column;
	flex: 1;
}
