@import "../../../../styles/build/scss/variables";

.stackContainer {
	flex: 1;

	.bodyContainer {
		flex: 1;

		.tabBtnContainer {
			.tabLabelContainer {
				gap: $logan-spacing-8;
				// TODO aqib missing token
				align-items: center;

				.activeTabText {
					// TODO aqib missing token
					text-transform: capitalize;
					color: $logan-text-dark-green;
				}

				.tabText {
					// TODO aqib missing token
					text-transform: capitalize;
					color: $logan-text-gray;
				}

				.closeIconContainer {
					// TODO aqib missing token
					cursor: pointer;
					padding: $logan-spacing-4;
					background-color: $logan-table-background-gray;
					border-radius: $logan-border-radius-4;

					.closeIcon {
						font-size: $logan-font-size-12;
						width: $logan-font-size-12;
						height: $logan-font-size-12;
					}
				}
			}
		}
	}
}

.tabPanelContainer {
	display: flex;
	flex-direction: column;
	border-top: $logan-border-width-1 solid $logan-outline-gray;
	padding: $logan-spacing-24;
	height: 0px;

	flex: 1 1 auto;
	overflow: auto;
}

.footerContainer {
	justify-content: space-between;
	// TODO aqib missing token
	flex-wrap: wrap;
	border-top: $logan-border-width-1 solid $logan-outline-gray;
	background: $logan-background-white;
	padding: $logan-spacing-16 $logan-spacing-24;

	.prevIcon {
		color: $logan-text-dark-green;
		font-size: $logan-font-size-16;
		width: $logan-font-size-16;
		height: $logan-font-size-16;
	}

	.nextIcon {
		color: $logan-background-white;
		font-size: $logan-font-size-16;
		width: $logan-font-size-16;
		height: $logan-font-size-16;
	}
}
