@import "../../styles/build/scss/variables";

.tabStackContainer {
	.MuiTabs-flexContainer {
		// TODO aqib missing token
		gap: $logan-spacing-8;
		flex-wrap: wrap;
	}

	.MuiButtonBase-root.MuiTab-root.Mui-selected button {
		// TODO aqib missing token
		border-radius: 6px;
		background: #fff;
		box-shadow: 0px 0px 0px 4px rgba(190, 230, 204, 0.6),
			0px 0px 1px 0px rgba(0, 0, 0, 0.1);
	}
}
