@import "../.././styles/build/scss/variables";

div.tablePaperContainer {
	display: flex;
	border-top-left-radius: $logan-border-radius-10;
	border-top-right-radius: $logan-border-radius-10;
	border-bottom-left-radius: $logan-border-radius-0;
	border-bottom-right-radius: $logan-border-radius-0;
	box-shadow: none;

	.tableContainer {
		.table {
			border-collapse: separate;
		}

		th {
			background-color: $logan-table-background-gray;
			border-bottom: $logan-border-width-2 solid $logan-table-border-green;
		}

		tbody td {
			border-bottom: $logan-border-width-1 solid $logan-table-border-gray;

			.truncatedText {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 275px;
			}

			.deleteIconBtn {
				padding: $logan-spacing-0;

				.deleteIcon {
					color: $logan-destructive-enabled;
					font-size: $logan-font-size-24;
				}
			}

			.folderNameContainer {
				gap: $logan-spacing-6;

				.folderIcon {
					font-size: $logan-font-size-20;
					color: $logan-check-box-outline;
				}
			}

			.fileNameContainer {
				gap: $logan-spacing-6;

				.fileIcon {
					font-size: $logan-font-size-16;
					color: $logan-text-gray;
				}
			}

			.actionsContainer {
				gap: $logan-spacing-8;

				.iconBtn {
					padding: $logan-spacing-6;

					.deleteIcon {
						color: $logan-destructive-enabled;
						font-size: $logan-font-size-24;
					}
				}
			}

			.checkboxInput {
				margin: 0px auto;
			}

			.disabledText {
				color: $logan-table-text-gray;
				cursor: not-allowed;
				text-decoration: none;
			}
		}

		.textCenter {
			text-align: center;

			.uploadIconContainer {
				display: inline-block;
				border-radius: $logan-border-radius-6;
				background: $logan-background-white;
				padding: $logan-spacing-4;
				cursor: pointer;

				&:hover {
					border-radius: $logan-border-radius-8;
					background: $logan-table-background-gray;
				}

				.uploadIcon {
					font-size: $logan-font-size-24;
				}
			}
		}

		.pipelineNumber {
			cursor: pointer;
			text-decoration: underline;
		}

		.conditionTimestamp {
			color: #1b9850;
		}

		tbody > tr {
			&:hover {
				background-color: transparent;
			}
		}

		.conditionRow {
			background-color: transparent;
		}

		// tbody > tr:nth-child(odd) {
		// background-color: $logan-table-background-white;

		// &:hover {
		// 	background-color: $logan-table-background-gray;
		// }
		// }

		// tbody > tr:nth-child(even) {
		// background-color: $logan-table-background-gray;

		// &:hover {
		// 	background-color: $logan-table-background-white;
		// }
		// }

		tbody > tr.highlightRow {
			border: 2px solid #2ecc71;
			background: rgba(190, 230, 204, 0.4);
		}
	}
}

div.squaredBorderContainer {
	border-radius: $logan-border-radius-0;
	max-height: 364px;
}

.incompleteStatusContainer {
	gap: $logan-spacing-8;
	border-bottom: $logan-border-width-1 solid #ec5656;
	width: fit-content;
}
