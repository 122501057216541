@import "../../../styles/build/scss/variables";

.modalBodyContainer {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	width: 540px;

	border-radius: $logan-border-radius-16;
	// TODO aqib missing token
	border: $logan-border-width-4 solid #bee6cc;
	background: $logan-background-white;

	padding: $logan-spacing-32;
	// TODO aqib missing token
	gap: 40px;

	.animationContainer div {
		width: 200px;
		height: 200px;
	}

	.textContainer {
		gap: $logan-spacing-8;

		.errorText {
			color: #26aa5e;
		}

		.errorSubText {
			color: $logan-text-gray;
		}
	}

	.btnContainer {
		background-color: $logan-destructive-enabled;

		&:hover {
			background-color: $logan-destructive-enabled;
		}
	}
}
