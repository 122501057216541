@import "../../../styles/build/scss/variables";

.cardContainer {
	.cardHeaderContainer {
		gap: $logan-spacing-4;
		margin-bottom: $logan-spacing-32;
	}

	.formSectionContainer {
		gap: $logan-spacing-48;

		.inputSectionContainer {
			flex-wrap: wrap;
			gap: $logan-spacing-20 $logan-spacing-24;
			justify-content: space-between;
		}

		.animationContainer div {
			width: 220px;
		}

		.inputContainer {
			gap: $logan-spacing-6;

			.errorIcon {
				font-size: $logan-font-size-20;
				color: $logan-destructive-enabled;
			}

			.datepicker {
				background: #f5f5f5;
				width: 264px;
				height: 48px;
				font-size: $logan-font-size-16;

				// TODO aqib missing token
				border: 0px;
				border-radius: $logan-border-radius-6;
				padding: $logan-spacing-12 $logan-spacing-10;
				color: $logan-text-dark-green;

				input {
					color: $logan-text-dark-green;
				}

				div,
				button {
					// TODO aqib missing token
					background-color: #f5f5f5;
					border: 0px;
					border-radius: $logan-border-radius-6;
				}
			}
		}

		.fullWidthContainer {
			min-width: 264px;
			width: 100%;
		}

		.inlineContainer {
			min-width: 264px;
			width: calc(50% - 12px);
		}
	}
}
