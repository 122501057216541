@import "../../../styles/build/scss/variables";

.react-calendar__tile--active {
	background-color: $logan-button-primary-enabled !important;
	color: $logan-text-white !important;
}

.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
	stroke: $logan-button-primary-enabled !important;
}

.react-calendar__tile:disabled abbr {
	color: $logan-text-light-gary;
}
