@import "../../../styles/build/scss/variables";

.multiSelect {
	min-height: 48px;

	display: flex;
	flex-direction: row;
	align-items: center;

	border-radius: $logan-border-radius-8;
	border: $logan-border-width-2 solid $logan-outline-green;
	background: $logan-background-white;

	> div {
		height: 100%;

		> div {
			height: 100%;
		}
	}
}
