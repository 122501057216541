@import "../.././styles/build/scss/variables";

.drawerContainer {
	> div:nth-child(3) {
		// TODO aqib missing token
		border-radius: $logan-border-radius-16 0px 0px $logan-border-radius-16;
		box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04);
		height: auto;
		min-height: 75vh;
		max-height: 100vh;
	}

	.drawerMainDiv {
		display: flex;
		min-width: 56vw;
		max-width: 97vw;
		overflow: hidden;
	}
}
