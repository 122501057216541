@import "../../styles/build/scss/variables";

.radioContainer {
	gap: $logan-spacing-16;
	flex-direction: row;
	align-items: center;

	.radioContainerHeader {
		color: $logan-text-black;
		// TODO aqib missing token
		width: 140px;
	}

	div {
		flex-direction: row;

		label {
			margin: 0;
		}
	}

	.radioGroup {
		.radio {
			color: $logan-check-box-outline;
		}
	}

	.radioLabel {
	}
}
