@import "../../../styles/build/scss/variables";

.secondarySelect {
	font-family: $logan-font-families-sofia-sans;
	font-weight: $logan-font-weights-regular;
	font-size: $logan-font-size-16;
	line-height: $logan-line-heights-24;
	letter-spacing: $logan-spacing-0;
	color: $logan-text-dark-green;

	// TODO aqib missing token
	background: #f5f5f5;
	border: 0;
	// padding: $logan-spacing-12 $logan-spacing-10;
	border-radius: $logan-border-radius-6;

	height: 48px;
	width: 264px;

	padding: 12px 10px;
}

.fullWidth {
	min-width: 264px;
	width: 100%;
}

.error {
	border-radius: $logan-border-radius-6;
	border: $logan-border-width-2 solid $logan-destructive-enabled;
	background: rgba(236, 86, 86, 0.15);
}
