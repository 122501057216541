@import "../../../styles/build/scss/variables";

button.primaryBtn {
	background-color: $logan-button-primary-enabled;
	border-radius: $logan-border-radius-8;
	padding: $logan-spacing-8 $logan-spacing-12;

	&:hover {
		background-color: $logan-button-primary-hover;
	}

	span {
		margin-left: $logan-spacing-0;
		margin-right: $logan-spacing-2;
	}

	.primaryBtnText {
		color: $logan-button-primary-text;
		text-transform: $logan-text-case-none;
	}
}
