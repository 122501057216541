@import "../../../styles/build/scss/variables";

p.body1 {
	font-family: $logan-font-families-sofia-sans;
	font-weight: $logan-font-weights-regular;
	font-size: $logan-font-size-20;
	line-height: $logan-line-heights-24;
	letter-spacing: $logan-spacing-0;
	color: $logan-text-dark-green;
}

p.semibold {
	font-weight: $logan-font-weights-semibold;
}

p.error {
	color: #ec5656;
}

p.link {
	color: $logan-link-enabled;
	cursor: pointer;
	text-decoration: underline;
}
